<template>
  <overlay-scrollbars class="page page-bosBusiness" ref="os">
    <div class="row row_top">
      <div class="box box_bosBusiness">
        <div class="col col_img"><img src="/img/la.png"></div>
        <div class="col col_content">
          <div class="ttl">Buy & Sell NET License</div>
          <p class="p1">{{ $store.getters.contentArr.legal_atlas_please_contact }}<br><a href="mailto:support@kyc-pay.com">{{ $store.getters.contentArr.suppport_email }}</a></p>
          <div class="footer">
            <div @click="showContactModal" class="btn">Buy or sell now</div>
          </div>
        </div>
      </div>
      <div class="box box_freeCons">
        <span class="dots dots-top"><Dots/></span>
        <span class="dots dots-bottom"><Dots/></span>
        <div class="content">{{ $store.getters.contentArr.legal_atlas_get_free_consultation }}</div>
        <div @click="openChat" class="btn">{{ $store.getters.contentArr.legal_atlas_get_free_consultation_button }} <Arrow class="img"/></div>
      </div>
    </div>
    <div class="box box_legalService">
      <div class="content">
        <div class="block block__legalService">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Busines offers</div>
          </div>
          <Table
            :options="businesOffers" :key="businesOffersKey"
            @change-page="getBusinesOffers"
            @apply="applyOffer"
            @scroll-top="toElTop"
            @sort="sortBy"
          />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Table from '@/components/elem/Table.vue'

import Arrow from '@/components/img/Arrow.vue'
import Dots from '@/components/img/Dots.vue'
import Bank2 from '@/components/img/Bank2.vue'
import Pagination from 'laravel-vue-pagination'
import axios from 'axios'

export default {
  components: {
    Table,
    Arrow,
    Dots,
    Bank2,
    Pagination,
  },
  data: () => ({
    businesOffersKey: 1,
    businesOffers: {
      objName: 'businesOffers',
      colsWidth: ['4%', '15%', '15%', '13%', '30%', '10%', '12%'],
      header: [
        { ttl: '#' },
        { ttl: 'Type of Business', sort: 'business_type' },
        { ttl: 'Type of License', sort: 'license_type' },
        { ttl: 'Country', sort: 'country_name' },
        { ttl: 'Description' },
        { ttl: 'Price' },
        { ttl: 'Buy' }
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      }
    }
  }),
  created() {
    this.getBusinesOffers()
  },
  methods: {
    getBusinesOffers ( page = 1 ) {
      const basePath = process.env.VUE_APP_BACKEND_API_URL + 'business-offers?page=' + page
      const sortParams = '&sort=' + this.businesOffers.sort.name + '&order=' + this.businesOffers.sort.order
      axios.get(basePath + sortParams)
        .then(({ data }) => {
          this.businesOffers.paginationData = data
          data.data.forEach(el => {
            const btnType = el[6] ? "cancel" : "apply-btn"
            el[6] = {
              type: btnType,
              html: "Apply",
              applyType: "apply-offer",
              id: el[0]
            }
            el[7] = {type: 'hidden-id', id: el[7] }
          }) 
          this.businesOffers.body = data.data
          if (this.businesOffers.sort.name) this.businesOffers.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    showContactModal () {
      this.$store.commit('setModal', {
        template: 'bos-contact-form',
        options: {}
      })
    },
    async applyOffer (data) {
      await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'business-offers/toogle-apply/' + data.id)
        .then(res => {
          this.$noty.success("Apply status was successfully changed")
          let offer = this.businesOffers.body.find(el => +el[0] === +data.id)
          offer[5].type = res.data.applied ? "cancel" : "apply-btn"
          this.businesOffersKey++
        })
        .catch(error => {
          console.dir(error)
        })
    },
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    openChat () {
      //Tawk_API.maximize()
    },
    sortBy (data) {
      this[data.obj].sort.loading = true
      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
        ? 'desc' : 'asc'
      this[data.obj].sort.name = data.sort
      this.getBusinesOffers(1)
    },
  }
}
</script>

<style lang="scss" scoped>
.page-bosBusiness {
  .row {
    &_top {
      display: flex;
      height: 16rem;
      margin-top: 1.5rem;
    }
  }

  .box {
    &_bosBusiness {
      width: 75%;
      height: 100%;
      margin: 1rem 1rem 1rem 0;
      background-color: #ffffff;
      display: flex;

      .col {
        width: 50%;

        &_img {
          display: flex;
          justify-content: center;

          img {
            height: 120%;
            position: relative;
            top: 100%;
            transform: translateY(-95%);
          }
        }

        &_content {
          padding: 1rem;
          display: flex;
          flex-direction: column;

          .ttl {
            color: #62CDAC;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 600;
            position: relative;
            padding-left: 3rem;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 2.25rem;
              height: 1px;
              background: #62CDAC;
              box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
              border-radius: 14px;
            }

          }

          p {
            color: #22252B;

            &.p1 {
              font-size: 14px;
              padding: .75rem 0 .5rem;
              flex-grow: 1;
            }
          }

          .footer {
            display: flex;
            justify-content: center;

            .btn {
              font-size: 18px;
              padding: 1rem 3rem;
              margin: .25rem 1rem;
              background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
              box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
              border-radius: 6px;
            }
          }
        }
      }
    }

    &_freeCons {
      width: calc(25% - 2rem);
      height: 100%;
      margin: 1rem 0 1rem 1rem;
      padding: 1.25rem 1.75rem;
      background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
      border-radius: 16px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;

      .dots {
        position: absolute;

        &-top {
          top: 2px;
          left: 1.75rem;
          width: 2rem;
        }

        &-bottom {
          bottom: -12px;
          right: 2rem;
          width: 2.75rem;
          transform: rotate(90deg);
          opacity: 0.5;
        }
      }

      .content {
        position: relative;
        font-size: 1.5rem;
        padding: 1.5rem .75rem 1.5rem 0;
        z-index: 1;

        @media (max-width: 1680px) {
          font-size: 20px;
        }

        @media (max-width: 1440px) {
          font-size: 18px;
        }

        @media (max-width: 1366px) {
          font-size: 16px;
        }
      }

      .btn {
        padding: 0.75rem 1.25rem;
        background: #272E35;
        border-radius: 6px;
        color: #62CDAC;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;

        ::v-deep svg {
          width: 1.5rem;
          height: 1.5rem;
          margin-left: 1rem;

          path {
            fill: #ffffff;
          }
        }
      }
    }

    &_legalService {
      margin-top: 3rem;
      background-color: #000000;

      .content {
        .block {
          margin: 1rem 0;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: 1rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }

          ::v-deep .tbl {
            position: relative;
            background-color: #000000;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              background: linear-gradient(124.81deg, #323940 0%, rgba(50, 57, 64, 0.9) 46.79%, rgba(0, 0, 0, 0.02) 100%);
              box-shadow:
                20px 30px 100px rgba(0, 0, 0, 0.05),
                inset -5px -5px 10px rgba(255, 255, 255, 0.05),
                inset 5px 5px 10px rgba(255, 255, 255, 0.05);
              backdrop-filter: blur(20px);
              opacity: 0.5;
            }

            > * {
              position: relative;
            }
          }

          &__legalService {
            .hdr {
              ::v-deep svg.img {
                path {
                  fill: #602e8f;
                }
              }
            }

            ::v-deep .tbl {
              &_header {
                background-color: rgba(96, 46, 143, 0.5);
              }
            }
          }
        }
      }
    }
  }
}

.pagination {
    background-color: white;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.page-link span {
    color: white;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    border: 1px solid #dee2e6;
    color: white !important;
}
</style>
