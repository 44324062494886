import { render, staticRenderFns } from "./BuyOrSellBusiness.vue?vue&type=template&id=355ce730&scoped=true&"
import script from "./BuyOrSellBusiness.vue?vue&type=script&lang=js&"
export * from "./BuyOrSellBusiness.vue?vue&type=script&lang=js&"
import style0 from "./BuyOrSellBusiness.vue?vue&type=style&index=0&id=355ce730&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "355ce730",
  null
  
)

export default component.exports